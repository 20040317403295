import React, { ChangeEvent, FC, ReactNode, useEffect, useState } from "react";
import { Box, Input, System, system } from "flicket-ui";
import styled from "styled-components";
import { useDebounce } from "use-debounce";
import { Icon } from "~components";
import { MagnifyingGlass } from "@phosphor-icons/react";

const StyledSearch = styled(Box)`
  display: flex;
  position: relative;
  height: 48px;
  width: 356px;

  box-shadow: ${(p) => p.theme.shadows.inner.sm};
  border-radius: ${(p) => p.theme.radii.sm};
  border: 1px solid ${(p) => p.theme.colors.N200};

  input {
    background: transparent;
    border: none;
    box-shadow: none;
    height: 100%;
    font-size: 16px;
    line-height: 150%;
    padding-left: 44px;
  }

  ${system}
`;

const SearchIcon = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 12px;
  left: 12px;
  font-size: 24px;
  height: 24px;
  pointer-events: none;
`;

interface SearchInputProps {
  onChange: (value: string) => void;
  placeholder?: string;
  initialValue?: string;
}

export const SearchInput: FC<SearchInputProps & System> = ({
  onChange,
  placeholder = "Search...",
  ...props
}) => {
  const initalValue = props.initialValue ?? "";

  const [text, setText] = useState(initalValue);
  const [value] = useDebounce(text, 200);

  useEffect(() => {
    onChange(value);
  }, [value]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) =>
    setText(e.target.value);

  return (
    <StyledSearch {...props}>
      <Input onChange={handleChange} placeholder={placeholder} value={text} />
      <SearchIcon>
        <Icon
          icon={<MagnifyingGlass size={24} weight="light" />}
          color="N800"
        />
      </SearchIcon>
    </StyledSearch>
  );
};
