import { Flex, Spinner } from "flicket-ui";
import React, { useState } from "react";
import { useSDK } from "~hooks";
import useScreenSize from "~hooks/useScreenSize";
import { getError, handlePromise, showToast } from "~lib";
import { Button } from "../common/Button";
import XeroLogo from "./assets/xeroinline.svg";

type XeroButtonProps =
  | {
      variant: "view";
      invoiceId: string;
    }
  | {
      variant: "generate";
      orderId: string;
      onInvoiceCreated: (invoiceId: string) => void;
    };

const XeroStyledButton = ({
  label,
  onClick,
}: {
  label: string;
  onClick?: () => void | Promise<void>;
}) => {
  const isMobile = useScreenSize().isTabletPortraitDown;
  const [loading, setLoading] = useState(false);

  const handleClick = async () => {
    if (!onClick) return;
    setLoading(true);
    await onClick();
    setLoading(false);
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <Button
      maxWidth={"260px"}
      paddingX={"1/4"}
      mr="6/4"
      style={{
        backgroundColor: "rgba(19,181,234,0.9)",
        paddingTop: "3px",
        paddingBottom: "2px",
      }}
      color={"white"}
      borderColor={"transparent"}
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      onClick={handleClick}
    >
      <Flex flexDir={"row"} alignItems="center" paddingX={6}>
        {!isMobile && <div style={{ paddingBottom: "2px" }}>{label}&nbsp;</div>}
        <XeroLogo fontSize="37px" />
      </Flex>
    </Button>
  );
};

const GenerateButton = ({
  orderId,
  onComplete,
}: {
  orderId: string;
  onComplete: (invoiceId: string) => void;
}) => {
  const { generateXeroInvoiceForOrderMutation } = useSDK();

  const generateInvoice = async () => {
    const [error, result] = await handlePromise(async () =>
      generateXeroInvoiceForOrderMutation({
        orderId,
      })
    );
    console.log(
      `🚀 ~ file: XeroInvoiceButton.tsx:67 ~ generateInvoice ~ result:`,
      result
    );

    if (error) {
      showToast(getError(error, "graphQL"), "error");
      return;
    }

    if (result) {
      onComplete(result.generateXeroInvoiceForOrder);
    }
  };

  return (
    <XeroStyledButton
      label={"Generate invoice"}
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      onClick={generateInvoice}
    />
  );
};

const ViewButton = ({ invoiceId }: { invoiceId: string }) => (
  <a
    href={`https://go.xero.com/AccountsReceivable/View.aspx?InvoiceID=${invoiceId}`}
    target={"_blank"}
    rel="noopener noreferrer"
  >
    <XeroStyledButton label={"View invoice on"} />
  </a>
);

export const XeroInvoiceButton = (props: XeroButtonProps) => {
  const { variant } = props;

  return variant === "generate" ? (
    <GenerateButton
      orderId={props.orderId}
      onComplete={props.onInvoiceCreated}
    />
  ) : (
    <ViewButton invoiceId={props.invoiceId} />
  );
};
