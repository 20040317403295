import React from "react";
import { Text, Flex, Stack } from "flicket-ui";
import { NamingFieldsOverviewProps } from ".";
import { useNamingFieldOptions } from "./hooks/useNamingFieldOptions";
import { FieldLabel } from "~features/generalAdmissionEvent/components/FormSectionHelpers";
import { useOrganization } from "~hooks";
import { OrganizationFeatures } from "~lib/features";

export const NamingFieldsOverview = ({
  fields,
  onClick,
  hideTitle = false,
}: NamingFieldsOverviewProps) => {
  const { namingFieldOptions } = useNamingFieldOptions(fields);
  const { organization, hasFeature } = useOrganization();

  return (
    <>
      {!hideTitle && (
        <Text color="N600" fontSize={3} fontWeight="extraBold" mb="1/2">
          Naming fields
        </Text>
      )}
      <Flex width={1} alignItems="center">
        <Stack gap={1} flexWrap="wrap">
          {fields
            ?.filter((f) => f.isRequired)
            .map(({ field }) => (
              <FieldLabel key={field}>
                {
                  Object.entries(namingFieldOptions).find(
                    ([, { field: f }]) => f === field
                  )?.[0]
                }
                *
              </FieldLabel>
            ))}

          {fields
            ?.filter((f) => !f.isRequired)
            .map(({ field }) => (
              <FieldLabel key={field}>
                {
                  Object.entries(namingFieldOptions).find(
                    ([, { field: f }]) => f === field
                  )?.[0]
                }
              </FieldLabel>
            ))}
          {!hasFeature(OrganizationFeatures.NewNamingFields) && (
            <Text
              color="P300"
              fontSize={3}
              fontWeight="medium"
              textDecoration="underline"
              cursor="pointer"
              onClick={onClick}
              lineHeight={"40px" as any}
            >
              Edit
            </Text>
          )}
        </Stack>
      </Flex>
    </>
  );
};
