import React, {
  FC,
  useState,
  useEffect,
  useRef,
  useMemo,
  MouseEvent,
} from "react";
import {
  Box,
  Divider,
  Flex,
  Link,
  PrimaryButton,
  SecondaryButton,
  SystemProps,
  Text,
  theme,
} from "flicket-ui";
import { get, startCase } from "lodash";
import { useSuperAdmin } from "~hooks/useSuperAdmin";
import styled from "styled-components";

import { useOrganization, useUser } from "~hooks";
import { getUrl } from "~lib";
import { Icon } from "~components/common/Icon";
import { PrintModal } from "~components/orders";
import { Modal } from "../Modal";
import {
  Printer,
  ShoppingCart,
  SignOut,
  UserCircle,
  IconProps as IconPropsPhosphor,
} from "@phosphor-icons/react";
import { useRouter } from "next/router";

const ICON_SIZE: IconPropsPhosphor["size"] = "24";
const ICON_WEIGHT: IconPropsPhosphor["weight"] = "light";

type DropdownProps = SystemProps;

export const DropdownToggle = styled.div<{ isOpen: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.12s ease-out;

  width: 40px;
  height: 40px;

  background-color: ${(p) =>
    p.isOpen ? p.theme.colors.N200 : p.theme.colors.white};

  border: 1px solid ${(p) => p.theme.colors.N300};
  border-radius: ${(p) => p.theme.radii.full};

  color: ${(p) => p.theme.colors.N800};
  font-size: ${(p) => p.theme.fontSizes[3]};
  letter-spacing: -0.5px;
  font-weight: ${(p) => p.theme.fontWeights.regular};

  cursor: pointer;

  &:hover {
    background-color: ${(p) => p.theme.colors.N200};
  }
`;

export const DropdownContent = styled(Box)`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 48px;
  right: -8px;
  padding: 16px 0;
  background-color: ${(p) => p.theme.colors.white};
  border-radius: ${(p) => p.theme.radii.sm};
  box-shadow: ${(p) => p.theme.shadows.hover};
  z-index: ${(p) => p.theme.zIndices.dropdown};

  > a,
  > div:not(.divider) {
    display: flex;
    align-items: center;
    min-width: 156px;
    padding: 8px 40px 8px 16px;
    color: ${(p) => p.theme.colors.N800};
    font-size: ${(p) => p.theme.fontSizes[3]};
    font-weight: ${(p) => p.theme.fontWeights.regular};
    white-space: nowrap;
  }

  > a {
    &:hover {
      background-color: ${(p) => p.theme.colors.N100};
      color: ${(p) => p.theme.colors.N800};
      opacity: 1;
    }
  }

  .icon {
    margin-right: ${(p) => p.theme.space[1]}px;
  }

  > div.title {
    text-transform: uppercase;
    font-size: ${(p) => p.theme.fontSizes[2]};
    color: ${(p) => p.theme.colors.N600};
    font-weight: ${(p) => p.theme.fontWeights.demiBold};
  }
`;

const findFirstCapital = (string: string) => {
  const match = /[A-Z]/g.exec(string);

  if (match) {
    return match[0];
  }

  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return get(string, "[0]");
};

export const Dropdown: FC<DropdownProps> = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isPrintModalOpen, setPrintModalOpen] = useState(false);
  const [isAccountSwitchModalOpen, setAccountSwitchModalOpen] = useState(false);
  const { logout, user, loggedInOrganizations } = useUser();
  const { isSuperAdmin, isSuperAdminDomain } = useSuperAdmin();
  const dropdownRef = useRef(null);
  const { organization: currentOrganization } = useOrganization();
  const [modalOpen, setModalOpen] = useState(false);

  const handleClick = (e) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    if (!dropdownRef?.current?.contains(e.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClick);

    return () => document.removeEventListener("click", handleClick);
  }, []);

  const handleOpen = (e: MouseEvent) => {
    e.preventDefault();
    setIsOpen(!isOpen);
  };

  const initials = user
    ? // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      `${findFirstCapital(user?.firstName)}${findFirstCapital(user?.lastName)}`
    : "";

  // A list of accounts that will be logged out when the user logs out on this subdomain.
  // If the user logs out and they signed in with a linked account, all accounts that were
  // logged in with that account will also be signed out.
  // If the current subdomain org was not logged in with a linked account, then the user
  // will only be logged out of the current subdomain org.
  const accountSlugsToBeLoggedOut = useMemo(() => {
    const currentOrgSlug = startCase(currentOrganization.slug);
    if (!loggedInOrganizations) {
      return [currentOrgSlug];
    }

    const isCurrentOrgLinked = loggedInOrganizations.find(
      (org) => org.slug === currentOrganization.slug
    )?.isLinked;
    if (!isCurrentOrgLinked) {
      return [currentOrgSlug];
    }

    const linkedSlugs = loggedInOrganizations
      .filter((org) => org.isLinked)
      .map((org) => startCase(org.slug));

    return linkedSlugs;
  }, [loggedInOrganizations]);

  const handleLogout = async () => {
    if (accountSlugsToBeLoggedOut.length > 1) {
      return setModalOpen(true);
    }

    await logout();
  };

  const {
    showOrgList,
    firstThreeOrgs,
    remainingOrgsCount,
    filteredOrgs,
  } = useMultipleOrganizations();

  return (
    <>
      <Box ref={dropdownRef} position="relative" {...props}>
        <DropdownToggle isOpen={isOpen} onClick={handleOpen}>
          {initials}
        </DropdownToggle>
        {isOpen && (
          <DropdownContent maxHeight={"400px"} overflowY="auto">
            {!isSuperAdminDomain && !isSuperAdmin && (
              <Link to={`${getUrl(currentOrganization?.slug)}/account`}>
                <Icon
                  icon={<UserCircle size={ICON_SIZE} weight={ICON_WEIGHT} />}
                />
                My Account
              </Link>
            )}
            {!isSuperAdminDomain && (
              <a
                onClick={() => {
                  setPrintModalOpen(true);
                }}
              >
                <Icon
                  icon={<Printer size={ICON_SIZE} weight={ICON_WEIGHT} />}
                />
                Printer settings
              </a>
            )}
            {!isSuperAdminDomain && (
              <Link to={getUrl(currentOrganization?.slug)}>
                <Icon
                  icon={<ShoppingCart size={ICON_SIZE} weight={ICON_WEIGHT} />}
                />
                Customer side
              </Link>
            )}
            {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
            <a href="#" onClick={handleLogout}>
              <Icon icon={<SignOut size={ICON_SIZE} weight={ICON_WEIGHT} />} />
              Sign out
            </a>

            {showOrgList && (
              <>
                {/* @ts-expect-error fix `className` in flicket ui */}
                <Divider className="divider" py={2} m={0} />
                <Text className="title">My organizations</Text>
                {filteredOrgs.map(({ slug, logoUrl }, i) => (
                  <a
                    key={i}
                    href={getUrl(slug, true)}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <Icon
                      icon={
                        <div
                          style={{
                            width: 24,
                            height: 24,
                            backgroundImage: `url(${logoUrl})`,
                            backgroundSize: "contain",
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "center",
                            borderRadius: theme.radii.xs,
                          }}
                        ></div>
                      }
                    />
                    {startCase(slug)}
                  </a>
                ))}
              </>
            )}
          </DropdownContent>
        )}

        <PrintModal
          isOpen={isPrintModalOpen}
          close={() => setPrintModalOpen(false)}
          isConfigure
        />
      </Box>
      <Modal
        header="Sign out"
        isOpen={modalOpen}
        close={() => setModalOpen(false)}
      >
        <div>
          <Text paddingBottom={2}>
            {
              "You're signed in with a linked account. Are you sure you want to sign out of these organizations?"
            }
          </Text>

          {accountSlugsToBeLoggedOut.map((account) => (
            <Text key={account} variant="heavy.L">
              {account}
            </Text>
          ))}
        </div>

        <Flex flexDir={"row"} float="right">
          <SecondaryButton
            marginRight={"3/4"}
            onClick={() => setModalOpen(false)}
          >
            Cancel
          </SecondaryButton>
          <PrimaryButton
            onClick={() => {
              setModalOpen(false);
              logout().catch((e) => console.error(e));
            }}
          >
            Sign out
          </PrimaryButton>
        </Flex>
      </Modal>
    </>
  );
};

function useMultipleOrganizations() {
  const { organization: currentOrganization } = useOrganization();
  const { loggedInOrganizations } = useUser();
  const { pathname } = useRouter();

  // Exclude current org
  const filteredOrgs = loggedInOrganizations?.filter(
    (org) => org.slug !== currentOrganization.slug
  );

  const firstThreeOrgs = filteredOrgs?.slice(0, 3);
  const remainingOrgsCount = filteredOrgs?.slice(3).length;

  const showOrgList =
    !pathname.startsWith("/pos") && loggedInOrganizations?.length > 1;

  return {
    showOrgList,
    firstThreeOrgs,
    remainingOrgsCount,
    filteredOrgs,
  };
}
