import { Copy, PencilSimple, Trash } from "@phosphor-icons/react";
import { pick } from "@styled-system/props";
import { Box, Flex, Price, Stack, SystemProps, Text } from "flicket-ui";
import { ReactNode, useEffect, useState } from "react";
import styled from "styled-components";
import { Divider, Dropdown, DropdownOption, Icon } from "~components";
import { TicketStatusLabel } from "../constants";
import { ListedReleaseAddon } from "../types";
import { Td, Tr } from "./Table";
import { HoverIconDropdownButton } from "./common/HoverIcon/HoverIcon";

export function Section({
  children,
  ...props
}: { children: ReactNode } & SystemProps) {
  return (
    <Box {...pick(props)}>
      {children}
      <Divider mb={4} mt={6} />
    </Box>
  );
}

export function Subsection({
  title,
  children,
}: {
  title: ReactNode;
  children: ReactNode;
}) {
  return (
    <Box mb={3}>
      <Text mb={1} fontSize={4} fontWeight="heavy" color={"N800"}>
        {title}
      </Text>
      {children}
    </Box>
  );
}

export function SectionTitle({
  title,
  description,
  onClick,
  actionLabel,
  cta,
  small = false,
  ...props
}: {
  title: ReactNode;
  description?: ReactNode;
  onClick?: () => void;
  actionLabel?: string;
  cta?: ReactNode;
  small?: boolean;
} & SystemProps) {
  return (
    <Stack
      gap={3}
      flexDir={{ _: "column", md: "row" }}
      mb={2}
      alignItems={{
        _: "flex-start",
        md: actionLabel ? "baseline" : undefined,
      }}
      {...pick(props)}
    >
      <Stack gap={1} flexDir="column" flex={1}>
        <Text variant={small ? "header.S" : "header.M"}>{title}</Text>

        {!!description && <Text variant={"small"}>{description}</Text>}
      </Stack>

      {!cta && actionLabel && (
        <Text
          variant="regular"
          cursor={"pointer"}
          color={"P300"}
          textDecoration={"underline"}
          onClick={onClick}
        >
          {actionLabel}
        </Text>
      )}
      {!actionLabel && cta}
    </Stack>
  );
}

const StyledIcon = styled(Icon)<{ $isVisible?: boolean }>`
  transform: ${(props) =>
    props.$isVisible ? "rotate(90deg)" : "rotate(0deg)"};
  transition: transform 0.1s ease-out;
`;

export function TextCollapse({
  title,
  initialOpen = false,
  hasError = false,
  children,
}: {
  title: string;
  initialOpen?: boolean;
  hasError?: boolean;
  children: ReactNode;
}) {
  const [isVisible, setIsVisible] = useState(initialOpen);
  const [errorState, setErrorState] = useState(false);
  const showChildren = isVisible || errorState;

  useEffect(() => {
    setErrorState(hasError);
  }, [hasError]);
  return (
    <>
      <Box mb={4}>
        <Flex alignItems={"center"} color="P300">
          <StyledIcon
            fontSize={5}
            icon={"chevron-right"}
            $isVisible={showChildren}
          />
          <Text
            textDecoration="underline"
            cursor="pointer"
            fontSize={3}
            onClick={() => !errorState && setIsVisible(!isVisible)}
          >
            <Flex alignItems={"center"}>{title}</Flex>
          </Text>
        </Flex>
      </Box>
      <Box display={showChildren ? "block" : " none"} as="section">
        {children}
      </Box>
    </>
  );
}

export function ListAddonWrapper({
  title,
  eventIsActive,
  onClickItem,
  onEdit,
  onCopy,
  onDelete,
  data,
}: {
  title: string;
  onEdit?: () => void;
  onCopy?: () => void;
  onDelete?: () => void;
  onClickItem?: () => void;
  data: ListedReleaseAddon;
  eventIsActive: boolean;
}) {
  const dropdownOptions: DropdownOption[] = [];

  if (onEdit) {
    dropdownOptions.push({
      label: "Edit addon",
      onClick: onEdit,
      icon: <PencilSimple />,
    });
  }

  if (onCopy) {
    dropdownOptions.push({
      label: "Make a copy",
      onClick: onCopy,
      icon: <Copy />,
    });
  }

  if (onDelete) {
    dropdownOptions.push({
      label: "Delete addon",
      onClick: onDelete,
      icon: <Trash />,
    });
  }

  const label: TicketStatusLabel = (() => {
    if (!data.isActive) return "Hidden";
    if (eventIsActive) return "On sale";
    return "Scheduled";
  })();

  return (
    <Tr onClick={onClickItem}>
      <Td>
        <Box cursor="pointer">
          <Text as="span" mr={2} color="N700" fontSize={3}>
            {title}
          </Text>

          <Text
            as="span"
            fontSize={"13px" as any}
            display={"inline-flex"}
            color={"N500"}
            mr={1}
          >
            {label}
          </Text>
        </Box>
      </Td>
      <Td textAlign="right">
        <Text color="N500" fontSize={2}>
          {data.totalSold ?? "0"} issued / {data.quantity || "-"}
        </Text>
      </Td>
      <Td textAlign="right" width="80px">
        <Text color="N700" fontSize={3}>
          {data.price ? <Price price={data.price} /> : "Free"}
        </Text>
      </Td>
      <Td textAlign="right" width="1px" pr={{ _: 0, md: 1 }}>
        <Dropdown
          DropdownButton={HoverIconDropdownButton}
          options={dropdownOptions}
          textAlign="left"
        />
      </Td>
    </Tr>
  );
}

export const FieldLabel = styled(Text).attrs({
  display: "inline-flex",
  fontSize: 3,
  borderRadius: "md",
  backgroundColor: "N100",
  py: 1,
  px: "12px",
  alignItems: "center",
})``;

export const ButtonText = styled(Text).attrs({
  color: "N700",
  display: "inline-flex",
  fontSize: "16px",
  fontWeight: "heavy",
})``;

export const Field = styled.fieldset`
  border: none;
  width: 100%;

  &:disabled {
    pointer-events: none;

    .react-select__indicators,
    .react-select__value-container {
      background-color: ${(p) => p.theme.colors.N100};
    }

    .react-select__control .react-select__dropdown-indicator svg {
      color: ${(p) => p.theme.colors.N400};
    }
  }
`;

export const SecondaryText = styled(Text).attrs({
  variant: "small",
})``;

export const PrimaryText = styled(Text).attrs({
  variant: "regular",
})``;

export const UnderlineText = styled(PrimaryText).attrs({
  textDecoration: "underline",
  cursor: "pointer",
  as: "span",
})``;
