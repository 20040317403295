import { useSessionStorage } from "usehooks-ts";
import { useRouter } from "next/router";
import { uniq } from "lodash";
import { OrganizationFeatures } from "~lib/features";
import { apiUrl } from "~config";
import { useEffect } from "react";

export const FeatureFlags = Object.values(OrganizationFeatures);
type FeatureFlag = typeof FeatureFlags[number];

export default function useSessionFeatureFlags() {
  const router = useRouter();

  const [value, setValue] = useSessionStorage<OrganizationFeatures[]>(
    "features",
    []
  );

  useEffect(() => {
    if (apiUrl.includes("api.flicket.co.nz")) return;

    if (router?.query.feature) {
      const featureFlags = Array.isArray(router.query.feature)
        ? router.query.feature
        : [router.query.feature];

      setValue(uniq([...value, ...(featureFlags as OrganizationFeatures[])]));
    }
  }, [router?.query.feature]);

  return value.reduce((acc, name) => ({ ...acc, [name]: true }), {}) as Record<
    FeatureFlag,
    true
  >;
}
