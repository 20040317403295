import styled from "styled-components";
import { Icon } from "~components/common/Icon";
import cn from "classnames";
import { DropdownButtonProps } from "~components";
import { DotsThree } from "@phosphor-icons/react";

const HoverIcon = styled(Icon).attrs((p) => ({
  borderRadius: "sm",
  p: "1/2",
  className: cn("hover-child", p.className),
}))`
  cursor: pointer;
  transition: background-color 0.12s ease-out;

  &:active,
  &.active {
    transition-duration: 0;
    background-color: ${(p) => p.theme.colors.N100};
  }

  // Prevents hover while in active state.
  &:not(:active, .active):hover {
    background-color: ${(p) => p.theme.colors.hoverBackground3};
  }
`;

export const HoverIconDropdownButton = ({
  isActive,
  onClick,
  ...props
}: DropdownButtonProps) => {
  return (
    <HoverIcon
      {...props}
      onClick={(e: MouseEvent) => {
        e.stopPropagation();
        onClick?.();
      }}
      className={isActive ? "active" : ""}
      color="N800"
      icon={<DotsThree weight="regular" size={24} />}
      data-testid="icon-dropdown-trigger"
    />
  );
};

export default HoverIcon;
