export enum OrganizationFeatures {
  DigitalEvents = "digitalEvents",
  DigitalMemberships = "digitalMemberships",
  VaccinePassport = "vaccinePassport",
  Tags = "tags",
  Points = "points",
  TempPOSOperators = "tempPOSOperators",
  Scout = "scout",
  Fiji = "fiji",
  Social = "social",
  XeroIntegration = "xeroIntegration",
  Account2account = "account2account",
  Forecast = "forecast",
  RenewalEmail = "renewalEmail",
  GroupLink = "groupLink",
  AutoRenewMemberships = "autoRenewMemberships",
  MembershipTransfers = "membershipTransfers",
  EventSetupGA = "eventSetupGA",
  EventList = "eventList",
  PinPaymentGateway = "pinPaymentGateway",
  Competitions = "competitions",
  ZoneAlternativeEntryTime = "zoneAlternativeEntryTime",
  TicketResale = "ticketResale",
  GuestCard = "guestCard",
  Presale = "presale",
  LegacyPrivateLinks = "legacyPrivateLinksTab",
  MultiScanning = "multiScanning",
  BroadcastFilters = "broadcastFilters",
  NewNamingFields = "newNamingFields",
  AskForDietaryRequirements = "askForDietaryRequirements",
  AskHowDidYouHearAboutUs = "askHowDidYouHearAboutUs",
}
